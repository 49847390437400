/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeI from "../../templates/PageTypeI"
import List from "../../components/List"

import TitleImg from "../../images/simcrest-apps-product-configurator.png"

import IconManual from "../../images/icon-manual.svg"
import IconLicense from "../../images/icon-license.svg"
import IconPrivacy from "../../images/icon-privacy.svg"

import BlueArrow from "../../images/blue-arrow.svg"

/***************************************************
  Page Data
***************************************************/

const summaryInfo = () => {
  return (
    <div className="simcrest-apps summary-info">
      <p className="spread">
        The Product Configurator is simple to use and saves you a lot of time.
        <br />
        <br />
        <i>
          Are your sales people spending hours building custom orders? Are
          custom orders difficult to proof for issues or error prone? Do you
          need to make sure the correct components and routings go into
          production orders?
        </i>
        <br />
        <br />
        Building custom sales or production orders can be very time consuming.
        Errors are often preventing fulfillment of orders or causing issues in
        production and the cost of correcting the errors can be staggering.
        <br />
        <br />
        The Product Configurator helps your employees building custom sales and
        production orders by using easy to follow questionnaires ensuring orders
        are complete and validated once the configuration is finished.
        <br />
        <br />
        There are two Configurators available.
      </p>
      <h5>Sales Configurator</h5>
      <p className="spread">
        The Sales Configurator will guide the user through a questionnaire and
        allow the user to see the configuration during the entire process. Upon
        completion, the sales document is populated with lines making up the
        configuration.
      </p>
      <h5>Production Configurator</h5>
      <p className="spread">
        The Production Configurator will guide the user through a questionnaire
        and allow the user to see the configuration during the entire process.
        It will create a production order from a line in the sales order. There
        are several choices when creating the production order (planned,
        firm-planned or released). It is possible to create production orders
        from each line in the sales order. Assembly bill of materials are also
        supported if production orders are not needed for certain
        configurations.
      </p>
      <h5>Features and Benefits</h5>
      <List
        flex
        className="concise dark"
        items={[
          {
            text: `Save time and ensure custom sales and production orders are created correctly.`,
          },
          {
            text: `Follow questionnaires to configure complex product configurations with ease.`,
          },
          {
            text: `Have full overview of the configuration during the entire process.`,
          },
          {
            text: `Works with sales orders, production orders and assembly bill of materials.`,
          },
        ]}
      />
      <div>
        <img src={TitleImg} />
        <div>
          <p>
            The Product Configurator can be acquired as an App in the Extension
            Market Place inside Dynamics 365 Business Central or as an On
            Premise App. Just click Free trial to install and try out the
            solution.
          </p>
          <div className="blue-arrow-link">
            <img className="blue-arrow" src={BlueArrow} alt="Arrow" />
            <a
              href="https://appsource.microsoft.com/en-us/product/dynamics-365-business-central/PUBID.sim_crest%7CAID.product_configurator%7CPAPPID.939aba54-7930-4e06-a8ae-12d9d8a78994"
              target="_blank"
            >
              See the App on Dynamics 365 Business Central
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeI
      title="Product Configurator"
      descripton="The Sales Configurator will guide the user through a questionnaire and allow the user to see the configuration during the entire process."
      linkSubPageGroupID="SimCrestAppsGroup"
      subTitle="Configurator for Microsoft Dynamics 365 Business Central"
      summaryInfo={summaryInfo}
      videos={[
        {
          title: "Demo of Product Configurator",
          embedCode: (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/DJxyI0gLuiA"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ),
        },
      ]}
      documents={[
        {
          title: "Manual",
          link: "/docs/manual-product-configurator.pdf",
          icon: IconManual,
        },
        {
          title: "License",
          link: "/docs/license-product-configurator.pdf",
          icon: IconLicense,
        },
        {
          title: "Privacy",
          link: "/docs/privacy-product-configurator.pdf",
          icon: IconPrivacy,
        },
      ]}
    />
  )
}
